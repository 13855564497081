


















































































































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import LDatePicker from '@/blueprint/components/ui/DatePicker.vue';
import { LandlordState } from '@/store/landlord/state';
import { QSelectOptions } from '@/interfaces/quasar';
import { ValidateFields } from '@/assets/mixins';
import { MutationPayload  } from '@/interfaces';
import { DurationMonths } from '@/assets/mixins';

export default Vue.extend({
  name: 'Form-DetailsDates-Component',
  components: {
    'input-form': Form,
    'ldate-picker': LDatePicker,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        bedroom: 1,
        toilet: 1,
        ensuite: 'No',
        type: 'House',
        tenancyStart: '',
        tenancyEnd: '',
      },
      rangeOptions: [] as QSelectOptions[],
      ensuiteOptions: [
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
        {
          label: 'Mixed',
          value: 'Mixed',
        },
      ] as QSelectOptions[],
      typeOptions: [
        {
          label: 'Flat',
          value: 'Flats',
        },
        {
          label: 'House',
          value: 'House',
        },
        {
          label: 'Halls',
          value: 'Halls',
        },
      ] as QSelectOptions[],
    };
  },
  computed: {
    defaultTenancyStart (): string {
      return String(new Date().getFullYear()) + '/09';
    },
    defaultTenancyEnd (): string {
      return String(new Date().getFullYear() + 1) + '/07';
    },
    durationMonths (): number {
      if (this.form.tenancyStart && this.form.tenancyEnd) {
        return DurationMonths(new Date(this.form.tenancyStart), new Date(this.form.tenancyEnd));
      }
      return this.$store.getters['landlord/tenancyDuration'];
    },
  },
  watch: {},
  created () {
    this.createRangeOptions();
    this.syncForm();
  },
  methods: {
    createRangeOptions () {
      this.rangeOptions = [];
      // Start: 1, End: 10
      for (let count: number = 1; count <= 10; count++) {
        // Create Label Objects
        let optionObject: QSelectOptions = {
          label: String(count),
          value: count,
        };
        // Add to the array
        this.rangeOptions.push(optionObject);
      }
    },
    syncForm () {
      const form: LandlordState['form'] = this.$store.state.landlord.form;
      this.form = Object.assign({}, form.details, form.dates);
    },
    touched () {
      this.$emit('touched');
    },
    validate () {
      let hasError: boolean = !this.validateFields(['tenancyStart', 'tenancyEnd']);
      // If no errors
      if (!hasError) {
        // Sync form
        this.updateVuexStore('form.details.bedroom', this.form.bedroom);
        this.updateVuexStore('form.details.toilet', this.form.toilet);
        this.updateVuexStore('form.details.ensuite', this.form.ensuite);
        this.updateVuexStore('form.details.type', this.form.type);
        this.updateVuexStore('form.dates.tenancyStart', this.form.tenancyStart);
        this.updateVuexStore('form.dates.tenancyEnd', this.form.tenancyEnd);
        // State that this section is valid
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs, true);
    },
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
