


















import Vue from 'vue';

export default Vue.extend({
  name: 'Form-Component',
  components: {},
  inject: [],
  mixins: [],
  props: {
    overrideButtonTxt: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      defaultButtonTxt: 'Next',
    };
  },
  computed: {
    submitButtonTxt (): string {
      return this.overrideButtonTxt ? this.overrideButtonTxt : this.defaultButtonTxt;
    },
  },
});
