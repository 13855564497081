



































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import { LandlordState } from '@/store/landlord/state';
import { MutationPayload, KeyValuePair } from '@/interfaces';
import { QSelectOptions } from '@/interfaces/quasar';
import { GenerateCypressName } from '@/assets/mixins';

export default Vue.extend({
  name: 'Form-RentIncludes-Component',
  components: { 'input-form': Form },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        rentIncludes: [] as KeyValuePair[],
      },
      amenityRange: [
         {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ] as QSelectOptions[],
    };
  },
  computed: {},
  watch: {},
  // Lifecycle Hooks
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    syncForm () {
      const form: LandlordState['form'] = this.$store.state.landlord.form;
      // Loop through array to remove Vuex observers
      for (const idx in form.rentIncludes) {
        if (form.rentIncludes[idx]) {
          const keyValuePair: KeyValuePair = {
            label: form.rentIncludes[idx].label,
            value: form.rentIncludes[idx].value,
          };
          this.form.rentIncludes.push(keyValuePair);
        }
      }
    },
    validate () {
      this.updateVuexStore('form.rentIncludes', this.form.rentIncludes);
      this.$emit('valid');
    },
    updateVuexStore (key: string, value: KeyValuePair[]) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
    touched () {
      this.$emit('touched');
    },
    generateCypressName (input: string) {
      return GenerateCypressName(input);
    },
  },
});
