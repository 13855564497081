















































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import { QSelectOptions } from '@/interfaces/quasar';
import { LandlordState } from '@/store/landlord/state';
import { ValidateFields } from '@/assets/mixins';
import { MutationPayload  } from '@/interfaces';

export default Vue.extend({
  name: 'Form-Other-Component',
  components: { 'input-form': Form },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        available: false,
        description: '',
      },
      rangeOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ] as QSelectOptions[],
    };
  },
  computed: {},
  watch: {},
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    validate () {
      let hasError: boolean = !this.validateFields(['description']);
      // If no errors
      if (!hasError) {
        // Sync form
        this.updateVuexStore('form.available', this.form.available);
        this.updateVuexStore('form.description', this.form.description);
        // State that this section is valid
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    touched () {
      this.$emit('touched');
    },
    syncForm () {
      this.form.available = (this.$store.state.landlord.form as LandlordState['form']).available;
      this.form.description = (this.$store.state.landlord.form as LandlordState['form']).description;
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs, true);
    },
    updateVuexStore (key: string, value: string | boolean) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
