














































































































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import { LandlordState } from '@/store/landlord/state';
import { QSelectOptions } from '@/interfaces/quasar';
import { ValidateFields } from '@/assets/mixins';
import { MutationPayload  } from '@/interfaces';
import { date } from 'quasar';

export default Vue.extend({
  name: 'Form-RentDeposit-Component',
  components: { 'input-form': Form },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        rentAnnually: null as unknown as string,
        depositTotal: null as unknown as string,
        depositScheme: '',
      },
      depositProtectionRange: [
        {
          label: 'Deposit Protection Services',
          value: 'Deposit Protection Services',
        },
        {
          label: 'My Deposits',
          value: 'My Deposits',
        },
        {
          label: 'Tenacy Deposit Scheme',
          value: 'Tenacy Deposit Scheme',
        },
        {
          label: 'Other Deposit Scheme',
          value: 'Other Deposit Scheme',
        },
        {
          label: 'No Deposit Protection',
          value: 'No Deposit Protection',
        },
      ] as QSelectOptions[],
    };
  },
  computed: {
    bedrooms (): number {
      return (this.$store.state.landlord as LandlordState).form.details.bedroom;
    },
    durationMonths (): number {
      return this.$store.getters['landlord/tenancyDuration'];
    },
    yearlyRentPerPerson (): string {
      let result: number = 0;
      const state: LandlordState = (this.$store.state.landlord as LandlordState);
      const annualRent: number = parseFloat(this.form.rentAnnually);
      const bedrooms: number = state.form.details.bedroom;
      if (this.durationMonths < 12) {
        result = (Math.ceil((annualRent / bedrooms) / 5) * 5);
      } else {
        result = (Math.ceil((parseFloat(this.monthlyRentPerPerson) * 12) / 5) * 5);
      }
      if (isNaN(result)) {
        result = 0;
      }
      return result.toFixed(2);
    },
    monthlyRentPerPerson (): string {
      let result: number = 0;
      const state: LandlordState = (this.$store.state.landlord as LandlordState);
      const annualRent: number = parseFloat(this.form.rentAnnually);
      const bedrooms: number = state.form.details.bedroom;
      result = ((annualRent / bedrooms) / this.durationMonths);
      if (isNaN(result)) {
        result = 0;
      }
      return result.toFixed(2);
    },
    weeklyRentPerPerson (): string {
      let result: number = 0;
      if (this.durationMonths < 12) {
        // Get tenancy start and end dates
        const state: LandlordState = (this.$store.state.landlord as LandlordState);
        const tenancyStart: Date = new Date(state.form.dates.tenancyStart);
        const tenancyEnd: Date = new Date(state.form.dates.tenancyEnd);
        // Calc duration in days
        const daysDifference: number = date.getDateDiff(tenancyEnd, tenancyStart, 'days') as unknown as number;
        // Convert to weeks
        const weeksDifference: number = Math.ceil(daysDifference / 7);
        // Calc Weekly Rent
        result = (parseFloat(this.yearlyRentPerPerson) / weeksDifference);
      } else {
        result = (parseFloat(this.yearlyRentPerPerson) / 52);
      }
      if (isNaN(result)) {
        result = 0;
      }
      return result.toFixed(2);
    },
    depositPerPerson (): string {
      let result: number = 0;
      // Get tenancy start and end dates
      const state: LandlordState = (this.$store.state.landlord as LandlordState);
      const annualDeposit: string = this.form.depositTotal;
      const bedrooms: number = state.form.details.bedroom;
      // Calculate result
      result = (parseFloat(annualDeposit) / bedrooms);
      if (isNaN(result)) {
        result = 0;
      }
      return result.toFixed(2);
    },
    calculatedHintRent (): string {
      return ((this.bedrooms * 400) * this.durationMonths).toFixed(2);
    },
    calculatedHintDeposit (): string {
      return (this.bedrooms * 450).toFixed(2);
    },
  },
  watch: {},
  // Lifecycle Hooks
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    syncForm () {
      const form: LandlordState['form'] = this.$store.state.landlord.form;
      this.form.rentAnnually = form.rent.annually as any; // Any'ed due to typescript false positive linting errors
      this.form.depositTotal = form.deposit.total as any; // Any'ed due to typescript false positive linting errors
      this.form.depositScheme = form.deposit.scheme;
    },
    touched () {
      this.$emit('touched');
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    validate () {
      let hasError: boolean = !this.validateFields(['annualRent', 'totalDeposit']);
      // If no errors
      if (!hasError) {
        // Sync form
        this.updateVuexStore('form.rent.annually', this.form.rentAnnually as unknown as number); // Any'ed due to typescript false positive linting errors
        this.updateVuexStore('form.deposit.total', this.form.depositTotal as unknown as number); // Any'ed due to typescript false positive linting errors
        this.updateVuexStore('form.deposit.scheme', this.form.depositScheme);
        // State that this section is valid
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
