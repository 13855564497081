































import Vue from 'vue';
import DeleteButton from '@/blueprint/components/ui/DeleteButton.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import { LandlordState } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Upsert-Image-Component',
  components: {
    'delete-btn': DeleteButton,
    'loading': Loading,
  },
  props: {
    image: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: '',
          url: '',
          uploaded: false,
        };
      },
    },
    imageIdx: {
      type: Number,
      required: true,
      default: -1,
    },
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  // Methods
  methods: {
    removeImage () {
      if (this.image.id) {
        // temp set cloud uploaded to false for loading effect
        this.image.uploaded = false;
        this.$blob.Delete(
          (this.update ? 'property' : 'property-staging'),
          (this.update ? (this.$store.state.landlord as LandlordState).form.extras.id : this.$store.state.landlord.user.id),
          this.image.id,
          this.image.url,
        ).then(() => {
          this.$emit('removeImage', this.imageIdx);
        }).catch((err) => {
          console.error(err);
        });
      }
    },
  },
});
