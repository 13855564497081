



























































































































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import LatLng from '@/blueprint/components/landlord/upsert/2-LatLng.vue';
import AddressFinder, { AutocompletedAddress } from '@/blueprint/components/landlord/upsert/AddressFinder.vue';
import { ValidateFields, QSelectFilterFn } from '@/assets/mixins';

export default Vue.extend({
  name: 'Form-Address-Component',
  components: { 'input-form': Form, 'latlng': LatLng, 'address-finder': AddressFinder },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data () {
    return {
      form: {
        line1: '14 Brandon Road',
        line2: '',
        line3: '',
        line4: '',
        city: 'High Wycombe',
        county: 'Buckinghamshire',
        postcode: 'HP12 4PJ',
      } as AutocompletedAddress,
      showLocation: false,
      filteredCities: [] as string[],
    };
  },
  computed: {
    possibleCities: {
      get (): string[] {
        return this.$store.state.possibleCities as string[];
      },
    },
  },
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    touched () {
      this.$emit('touched');
      // Scroll to bottom
    },
    overrideSection () {
      // If fields are valid
      let hasError: boolean = !ValidateFields(this, ['line1', 'line2', 'line3', 'line4', 'city', 'county', 'postcode']);
      // Determine if city in possibleCities
      if (!this.possibleCities.includes(this.form.city)) {
        hasError = true;
        this.$q.notify({
          message: 'Please enter a valid city',
          color: 'red',
          timeout: 5000,
        });
      }
      // If no errors, geocode
      if (!hasError) {
        // Generate emit payload
        const payload: Record<string, string | AutocompletedAddress> = {
          component: 'LatLng',
          data: this.form,
        };
        this.$emit('setComponent', payload);
      } else {
        this.$emit('scrollBottom');
      }
    },
    syncForm () {
      this.form = Object.assign({}, this.$store.state.landlord.form.address);
    },
    filterFn (val: string, update: (func: () => void) => void, abort: () => void) {
      QSelectFilterFn(val, update, abort, this, this.possibleCities, 'filteredCities');
    },
    populateForm (address: AutocompletedAddress) {
      // Update form
      this.form = address;
      // Wait till after render is done
      this.$nextTick(() => {
        // State it is touched
        this.touched();
        // Submit
        // this.overrideSection();
      });
    },
  },
});
