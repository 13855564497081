

























import Vue from 'vue';
import { date } from 'quasar';
import { RootStore } from '@/store';

export default Vue.extend({
  name: 'UI-DatePicker-Component',
  props: {
    model: {
      type: String,
      required: true,
      default: '',
    },
    defaultStart: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => {
    return {
      dateValue: '',
      dateMask: 'YYYY/MM/DD',
      dateMaskInput: '####/##/##',
      rules: [] as any[],
    };
  },
  // Lifecycle Hooks
  created () {
    // Set mask and mask input
    const rootStore: RootStore = (this.$store.state as RootStore);
    this.dateMask = rootStore.dateMask;
    this.dateMaskInput = rootStore.dateMaskInput;
    // Initlaise
    this.dateValue = date.formatDate(this.model, this.dateMask);
    // Determine rules
    if (this.required) {
      this.rules.push((val: string) => !!val || '* Required');
    }
  },
  // Methods
  methods: {
    emitValue () {
      this.touched();
      // Close the popup
      const popup: Vue & { hide: () => void } = this.$refs['popup'] as Vue & { hide: () => void };
      popup.hide();
      try {
        this.$emit('input', new Date(this.dateValue).toISOString());
      } catch {
        this.$emit('input', '');
      }
    },
    touched () {
      this.$emit('touched');
    },
  },
});
