



































































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import DeleteButton from '@/blueprint/components/ui/DeleteButton.vue';
import { LandlordState } from '@/store/landlord/state';
import { MutationPayload, KeyValuePair } from '@/interfaces';
import { ValidateFields } from '@/assets/mixins';

export default Vue.extend({
  name: 'Form-Fees-Component',
  components: {
    'input-form': Form,
    'delete-btn': DeleteButton,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        fees: [] as KeyValuePair[],
      },
      labelRefs: [] as string[],
      valueRefs: [] as string[],
    };
  },
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    touched () {
      this.$emit('touched');
    },
    validate () {
      const refValues: string[] = this.labelRefs.concat(this.valueRefs);
      const hasError: boolean = !this.validateFields(refValues);
      if (!hasError) {
        this.updateVuexStore('form.fees', this.form.fees);
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    syncForm () {
      const vuexForm: LandlordState['form'] = this.$store.state.landlord.form;
      // Loop through array to remove Vuex observers
      for (const idx in vuexForm.fees) {
        if (vuexForm.fees[idx]) {
          this.form.fees.push(Object.assign({}, vuexForm.fees[idx]));
          // Store refs
          this.labelRefs.push(this.generateRefValue(parseInt(idx), 'label'));
          this.labelRefs.push(this.generateRefValue(parseInt(idx), 'value'));
        }
      }
    },
    generateRefValue (idx: number, postfix: string) {
      return 'fee' + String(idx) + postfix;
    },
    addFee () {
      this.touched();
      const feeLimit: number = 5;
      if (this.form.fees.length < feeLimit) {
        // Create new fee
        const newFee: KeyValuePair = {
          label: '',
          value: '',
        };
        this.form.fees.push(newFee);
        // Wait for fee to render then scroll to bottom
        this.$nextTick(() => {
          this.$emit('scrollBottom');
        });
        // Generate new refs
        const idx: number = (this.form.fees.length - 1);
        this.labelRefs.push(this.generateRefValue(idx, 'label'));
        this.labelRefs.push(this.generateRefValue(idx, 'value'));
      } else {
        this.$q.notify({
          message: `Cannot add more than ${feeLimit} fees to a property, contact support if needed!`,
          color: 'red',
          position: 'bottom',
          timeout: 3000,
          textColor: 'white',
        });
      }
    },
    removeFee (idx: string) {
      this.touched();
      this.form.fees.splice(parseInt(idx), 1);
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    updateVuexStore (key: string, value: KeyValuePair[]) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
