








































import Vue from 'vue';
import {} from 'googlemaps';
import Loading from '@/blueprint/components/ui/Loading.vue';
import { MutationPayload  } from '@/interfaces';

export default Vue.extend({
  name: 'Form-LatLng-Component',
  components: { Loading },
  props: {
    overrideData: {
      type: Object,
      required: false,
      default: () => {
        return {
          line1: '',
          line2: '',
          line3: '',
          line4: '',
          city: '',
          county: '',
          postcode: '',
        };
      },
    },
  },
  data () {
    return {
      loadingLocations: false,
      geocoder: new google.maps.Geocoder(),
      possibleLocations: [] as google.maps.LatLng[],
      possibleLocationsIdx: -1,
    };
  },
  mounted () {
    this.geocodeAddress();
  },
  // Methods
  methods: {
    flattenAddress () {
      let address: string = '';
      address = address.concat(
        this.overrideData.line1,
        this.overrideData.line2 ? ' ' + this.overrideData.line2 : '',
        this.overrideData.line3 ? ' ' + this.overrideData.line3 : '',
        this.overrideData.line4 ? ' ' + this.overrideData.line4 : '',
        this.overrideData.city ? ' ' + this.overrideData.city : '',
        this.overrideData.county ? ' ' + this.overrideData.county : '',
        this.overrideData.postcode ? ' ' + this.overrideData.postcode : '',
        ' UK',
      );
      return address;
    },
    geocodeAddress () {
      // Enable loader
      this.loadingLocations = true;
      // Initialise new geocoder
      if (this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }
      // Geocode address
      this.geocoder.geocode({address: this.flattenAddress()}, (results, status) => {
        if (status === 'OK') {
          // Reset exisiting locations
          this.possibleLocations = [];
          // Load new results
          for (let index in results) {
            if (results[index]) {
              const result: google.maps.GeocoderResult = results[index];
              this.possibleLocations.push(result.geometry.location);
              this.possibleLocationsIdx = -1;
            }
          }
          // Load first location
          this.loadNextLocation();
        } else {
          console.error('[Google Geocoder] - Unable to gecode as status: ' + status);
          window.alert('Google Maps Services is unable to locate property');
        }
        this.loadingLocations = false;
      });
    },
    loadNextLocation () {
      if (this.possibleLocationsIdx < (this.possibleLocations.length - 1)) {
        this.possibleLocationsIdx++;
        if (this.possibleLocationsIdx === 0 && window.navigator.vendor === 'Apple Computer, Inc.') {
          this.loadMap(this.possibleLocations[this.possibleLocationsIdx]);
          setTimeout(() => {
            this.loadMap(this.possibleLocations[this.possibleLocationsIdx]);
          }, 500);
        } else {
          this.loadMap(this.possibleLocations[this.possibleLocationsIdx]);
        }
      }
    },
    loadMap (latlng: any) {
      // Create the marker
      let marker: google.maps.Marker = new google.maps.Marker({
         position: latlng,
        title: this.overrideData.line1,
        icon: '/img/app/icons/result-default.png',
        clickable: false,
      });
      // Set the map view options
      let mapOptions: any = {
        center: latlng,
        zoom: 16,
        scrollwheel: false,
        scaleControl: false,
        fullscreenControl: false,
        zoomControl: false,
      };
      // Get DOM element
      let map: google.maps.Map<Element> = new google.maps.Map(this.$refs.map as Element, mapOptions);
      // Set marker
      marker.setMap(map);
    },
    back () {
      this.$emit('setComponent', 'Address');
    },
    locationCorrect () {
      // Sync form
      this.updateVuexStore('form.address.line1', this.overrideData.line1);
      this.updateVuexStore('form.address.line2', this.overrideData.line2);
      this.updateVuexStore('form.address.line3', this.overrideData.line3);
      this.updateVuexStore('form.address.line4', this.overrideData.line4);
      this.updateVuexStore('form.address.city', this.overrideData.city);
      this.updateVuexStore('form.address.county', this.overrideData.county);
      this.updateVuexStore('form.address.postcode', this.overrideData.postcode);
      this.updateVuexStore('form.address.latlng.lat', this.possibleLocations[this.possibleLocationsIdx].lat());
      this.updateVuexStore('form.address.latlng.lng', this.possibleLocations[this.possibleLocationsIdx].lng());
      // State that this section is valid
      this.$emit('valid');
    },
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
