var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-form',{attrs:{"overrideButtonTxt":(_vm.update && !_vm.section.valid) ? 'Update' : null},on:{"submit":function($event){return _vm.validate()}}},[_c('template',{slot:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Annual Rent For Property")]),_c('q-input',{ref:"annualRent",staticClass:"no-margin-top",attrs:{"data-cy":"upsert-rent-input","debounce":"250","prefix":"£","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length > 2 || 'Please use 2 characters at minimum'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; },
            function (val) { return !isNaN(val) || 'Please enter a valid price e.g. 100.00'; }
          ]},on:{"input":function($event){return _vm.touched()}},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_c('p',[_vm._v("E.g. "+_vm._s(_vm.bedrooms)+" People at £400.00 a month for "+_vm._s(_vm.durationMonths)+" months would be £"+_vm._s(_vm.calculatedHintRent))])]},proxy:true}]),model:{value:(_vm.form.rentAnnually),callback:function ($$v) {_vm.$set(_vm.form, "rentAnnually", $$v)},expression:"form.rentAnnually"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 estimate parent"},[_c('label',{staticClass:"title"},[_vm._v("Rent Estimate (Per Person)")])]),_c('div',{staticClass:"col-4 estimate child"},[_c('label',{staticClass:"title"},[_vm._v("Weekly")]),_c('p',{staticClass:"value",attrs:{"data-cy":"upsert-rentbreakdown-weekly"}},[_vm._v("£"+_vm._s(_vm.weeklyRentPerPerson))])]),_c('div',{staticClass:"col-4 estimate child"},[_c('label',{staticClass:"title"},[_vm._v("Monthly")]),_c('p',{staticClass:"value",attrs:{"data-cy":"upsert-rentbreakdown-monthly"}},[_vm._v("£"+_vm._s(_vm.monthlyRentPerPerson))])]),_c('div',{staticClass:"col-4 estimate child"},[_c('label',{staticClass:"title"},[_vm._v("Yearly")]),_c('p',{staticClass:"value",attrs:{"data-cy":"upsert-rentbreakdown-yearly"}},[_vm._v("£"+_vm._s(_vm.yearlyRentPerPerson))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Total Deposit For Property")]),_c('q-input',{ref:"totalDeposit",staticClass:"no-margin-top",attrs:{"data-cy":"upsert-deposit-input","debounce":"250","prefix":"£","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length > 2 || 'Please use 2 characters at minimum'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; },
            function (val) { return !isNaN(val) || 'Please enter a valid price e.g. 100.00'; }
          ]},on:{"input":function($event){return _vm.touched()}},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_c('p',[_vm._v("E.g. "+_vm._s(_vm.bedrooms)+" People at £450.00 deposit per person would be £"+_vm._s(_vm.calculatedHintDeposit))])]},proxy:true}]),model:{value:(_vm.form.depositTotal),callback:function ($$v) {_vm.$set(_vm.form, "depositTotal", $$v)},expression:"form.depositTotal"}})],1),_c('div',{staticClass:"col-12",staticStyle:{"margin-top":"2rem"}},[_c('label',[_vm._v("Deposit Protection Scheme")]),_c('q-select',{attrs:{"data-cy":"upsert-depositscheme-input","emit-value":"","map-options":"","outlined":"","align":"center","hide-underline":"","options":_vm.depositProtectionRange},on:{"input":function($event){return _vm.touched()}},model:{value:(_vm.form.depositScheme),callback:function ($$v) {_vm.$set(_vm.form, "depositScheme", $$v)},expression:"form.depositScheme"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 estimate margined"},[_c('label',{staticClass:"title"},[_vm._v("Deposit Estimates (Per Person)")]),_c('p',{staticClass:"value",attrs:{"data-cy":"upsert-depositbreakdown-perperson"}},[_vm._v("£"+_vm._s(_vm.depositPerPerson))])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }