var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-form',{attrs:{"overrideButtonTxt":(_vm.update && !_vm.section.valid) ? 'Update' : null},on:{"submit":function($event){return _vm.validate()}}},[_c('template',{slot:"form"},[_vm._l((_vm.form.fees),function(fee,idx){return _c('div',{key:idx,staticClass:"fee-container"},[_c('delete-btn',{staticClass:"remove-fee",attrs:{"rounded":""},on:{"delete":function($event){return _vm.removeFee(idx)}}}),_c('h6',[_vm._v("Fee #"+_vm._s((idx + 1)))]),_c('q-input',{ref:_vm.generateRefValue(idx, 'label'),refInFor:true,attrs:{"data-cy":_vm.generateRefValue(idx, 'label'),"label":"Fee Name","rules":[
          function (val) { return !!val || '* Required'; },
          function (val) { return val.length > 3 || 'Please use 3 characters at minimum'; },
          function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; }
        ]},on:{"input":function($event){return _vm.touched()}},model:{value:(fee.label),callback:function ($$v) {_vm.$set(fee, "label", $$v)},expression:"fee.label"}}),_c('q-input',{ref:_vm.generateRefValue(idx, 'value'),refInFor:true,attrs:{"prefix":"£","data-cy":_vm.generateRefValue(idx, 'value'),"label":"Fee Value","hint":"Fee value per person","rules":[
          function (val) { return !!val || '* Required'; },
          function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; },
          function (val) { return !isNaN(val) || 'Please enter a valid price estimate e.g. 100.00'; }
        ]},on:{"input":function($event){return _vm.touched()}},model:{value:(fee.value),callback:function ($$v) {_vm.$set(fee, "value", $$v)},expression:"fee.value"}})],1)}),_c('q-btn',{staticClass:"full-width",attrs:{"data-cy":"upsert-add-fee-btn","type":"button","color":"primary","no-caps":"","unelevated":"","ripple":false},on:{"click":function($event){return _vm.addFee()}}},[_vm._v("Add Fee")])],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }