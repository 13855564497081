














































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import LDatePicker from '@/blueprint/components/ui/DatePicker.vue';
import { LandlordState, Certificate } from '@/store/landlord/state';
import { ValidateFields } from '@/assets/mixins';
import { MutationPayload } from '@/interfaces';

export default Vue.extend({
  name: 'Form-Certificates-Component',
  components: {
    'input-form': Form,
    'ldate-picker': LDatePicker,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        certificates: [] as Certificate[],
      },
      certRefRefs: [] as string[],
      certExpiryRefs: [] as string[],
    };
  },
  computed: {},
  watch: {},
  // beforeCreate () {},
  created () {
    this.syncForm();
  },
  // Methods
  methods: {
    touched () {
      this.$emit('touched');
    },
    generateRefValue (idx: number, postfix: string) {
      return 'cert' + String(idx) + postfix;
    },
    syncForm () {
      const form: LandlordState['form'] = this.$store.state.landlord.form;
      // Loop through array to remove Vuex observers
      for (const idx in form.certificates) {
        if (form.certificates[idx]) {
          // Populate form
          const newCertificate: Certificate = {
            label: form.certificates[idx].label,
            ref: form.certificates[idx].ref,
            expiry: form.certificates[idx].expiry,
          };
          this.form.certificates.push(newCertificate);
          // Store refs
          this.certRefRefs.push(this.generateRefValue(parseInt(idx), 'ref'));
          this.certExpiryRefs.push(this.generateRefValue(parseInt(idx), 'expiry'));
        }
      }
    },
    validateFields (inputs: string[], inChildComponent: boolean) {
      return ValidateFields(this, inputs, inChildComponent);
    },
    validate () {
      const refs: string[] = this.certRefRefs.concat(this.certExpiryRefs);
      const hasError: boolean = !this.validateFields(refs, true);
      if (!hasError) {
        this.updateVuexStore('form.certificates', this.form.certificates);
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    updateVuexStore (key: string, value: Certificate[]) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});
