














































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import PreviewImage from '@/blueprint/components/landlord/upsert/Image.vue';
import { BlobTypes } from '@/assets/clients/blobClient';
import { Blob } from '@lordly/models2/interfaces';
import { LandlordState } from '@/store/landlord/state';
import { MutationPayload } from '@/interfaces';
import draggable from 'vuedraggable';

export default Vue.extend({
  name: 'Form-Images-Component',
  components: {
    'input-form': Form,
    'preview-image': PreviewImage,
    'draggable': draggable,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        images: [] as CustomImageObject[],
      },
    };
  },
  created () {
    // Sync form from Vuex
    this.syncForm();
    // Get staged images if adding new property
    if (!this.update) {
      this.loadFromPropertyStaging();
    }
  },
  methods: {
    validate () {
      this.syncStore();
      this.$emit('valid');
    },
    touched () {
      this.$emit('touched');
    },
    submittedNewPropertyImages (e: any) {
      this.touched();
      for (const idx in e.target.files) {
        if (e.target.files[idx] && parseInt(idx) < 20) {
          // Extract file
          const file: File = e.target.files[idx] as File;
          // If file is of image type and jpg, jpeg or png
          if (/\.(jpe?g|png)$/i.test(file.name)) {
            // Add images to form array
            const image: CustomImageObject = {
              id: '',
              url: '',
              uploaded: false,
            };
            // Add image to form
            this.form.images.push(image);
            const imageIdx: number = this.form.images.length - 1;
            let entity: BlobTypes = 'property-staging';
            // Handle update here
            if (this.update) {
              entity = 'property';
            }
            // Create the blob
            this.$blob.Create(entity, this.$store.state.landlord.user.id, null, file).then(
              (blobs: Blob[]) => {
                // Update created blob info
                if (blobs && blobs.length > 0) {
                  this.form.images[imageIdx].id = blobs[0].id;
                  this.form.images[imageIdx].url = blobs[0].url;
                  this.form.images[imageIdx].uploaded = true;
                  // If update mode, add image information to Vuex Property
                  if (this.update) {
                    this.syncStore();
                  }
                }
              },
            ).catch(
              () => {
                // Remove image
                this.form.images.splice(imageIdx, 1);
              },
            );
          }
        }
      }
      // Reset form
      ((this.$refs.inputForm as Vue).$refs.form as HTMLFormElement).reset();
    },
    loadFromPropertyStaging () {
      this.$blob.Get('property-staging', this.$store.state.landlord.user.id).then(
        (blobs) => {
          this.form.images = [];
          // Transform data
          blobs.map((blob) => {
            this.form.images.push(Object.assign({}, blob, { uploaded: true }));
          });
        },
      ).catch(
        (err) => {
          console.error(err);
        },
      );
    },
    removeImage (idx: number) {
      this.touched();
      this.form.images.splice(idx, 1);
      // If in update mode, sync images array
      if (this.update) {
        this.syncStore();
      }
    },
    syncForm () {
      const vuexForm: LandlordState['form'] = this.$store.state.landlord.form;
      // Loop through array to remove Vuex observers
      for (const idx in vuexForm.images) {
        if (vuexForm.images[idx]) {
          this.form.images.push(Object.assign({}, vuexForm.images[idx], { uploaded: true }));
        }
      }
    },
    syncStore () {
      // Remove additional attributes from blobs
      const cleanedImages: Blob[] = this.form.images.map((image) => {
        const clonedImage: CustomImageObject = Object.assign({}, image);
        delete clonedImage.uploaded;
        return clonedImage;
      });
      // Sync
      this.updateVuexStore('form.images', cleanedImages);
    },
    updateVuexStore (key: string, value: Blob[]) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
  },
});

interface CustomImageObject {
  id: string;
  url: string;
  uploaded: boolean;
}
