



























import Vue from 'vue';
import { debounce } from 'quasar';
import axios, { AxiosResponse } from 'axios';
import {} from 'googlemaps';

import { QSelectOptions } from '@/interfaces/quasar';

export default Vue.extend({
  name: 'Address-Finder-Component',
  components: {},
  inject: [],
  mixins: [],
  props: {},
  data: () => {
    return {
      searchTerm: '',
      googleAutoCompleteService: {} as google.maps.places.Autocomplete,
      result: {
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        city: '',
        county: '',
        postcode: '',
      } as AutocompletedAddress,
    };
  },
  computed: {},
  watch: {},
  // Lifecycle Hooks
  // beforeCreate () {},
  // beforeMount () {},
  mounted () {
    this.setupGoogleAutoCompleteService();
  },
  // beforeUpdate () {},
  // updated () {},
  // beforeDestroy () {},
  // destroyed () {},
  // Methods
  methods: {
    setupGoogleAutoCompleteService () {
      // Get input DOM element
      const inputDOM: HTMLInputElement = ((this.$refs['searchInput'] as Vue).$refs.input) as HTMLInputElement;
      // Create the autocomplete object, restricting the search predictions to geographical location types.
      this.googleAutoCompleteService = new google.maps.places.Autocomplete(inputDOM,
        {
          types: ['geocode'],
          componentRestrictions: {
            country: 'GB', // DevNote: Restricted to UK but can be string[] to match other countries
          },
        });
      // Avoid paying for data that you don't need by restricting the set of place fields that are returned to just the address components.
      this.googleAutoCompleteService.setFields(['address_component']);
      // When the user selects an address from the drop-down, populate the address fields in the form.
      this.googleAutoCompleteService.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress () {
      // Reset the form
      this.resetAddress();
      // Extract result
      const place: google.maps.places.PlaceResult = this.googleAutoCompleteService.getPlace();
      // Iterate through to determine address
      for (const idx in place.address_components) {
        if (place.address_components[parseInt(idx)]) {
          const component: google.maps.GeocoderAddressComponent = place.address_components[parseInt(idx)];
          // Try populating address
          if (component && component.types && component.types.length > 0) {
            // Try parse addressline1
            if (component.types.includes('premise') || component.types.includes('street_number') || component.types.includes('route')) {
              // Get premise or house number
              if (component.types.includes('premise') || component.types.includes('street_number')) {
                this.result.line1 = '';
                this.result.line1 += component.long_name;
              }
              // Determine whether to append to replace street name
              if (component.types.includes('route')) {
                if (this.result.line1.length > 0) {
                  this.result.line1 += ' ' + component.long_name;
                } else {
                  this.result.line1 = component.long_name;
                }
              }
            }
            // Try parse address line 2
            if (component.types.includes('sublocality_level_1')) {
              this.result.line2 = component.long_name;
            }
            // Try parse address line 3
            if (component.types.includes('locality')) {
              this.result.line3 = component.long_name;
            }
            // Try parse to postaltown to form city
            if (component.types.includes('postal_town')) {
              this.result.city = component.long_name;
            }
            // Try parse county
            if (component.types.includes('administrative_area_level_2')) {
              this.result.county = component.long_name;
            }
            // Try parse postcode
            if (component.types.includes('postal_code')) {
              this.result.postcode = component.long_name;
            }
          }
        }
      }
      this.$emit('result', this.result);
    },
    resetAddress () {
      this.result = {
         line1: '',
          line2: '',
          line3: '',
          line4: '',
          city: '',
          county: '',
          postcode: '',
      };
    },
    async searchForAddress () {
      // Target: Google Maps - Use First
      // Target: https://addressian.co.uk - Use last - 200 per month (6 per day)
      // Target: https://getaddress.io - Use 2nd (20 per day)
      // Target: https://ideal-postcodes.co.uk/postcode_lookup - Use 1st - (15 Per Day)
      console.log('Implement');
    },
  },
});

export interface AutocompletedAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  city: string;
  county: string;
  postcode: string;
}
