







































































































import Vue from 'vue';
import Address from '@/blueprint/components/landlord/upsert/1-Address.vue';
import LatLng from '@/blueprint/components/landlord/upsert/2-LatLng.vue';
import DetailsDates from '@/blueprint/components/landlord/upsert/3-DetailsDates.vue';
import RentDeposit from '@/blueprint/components/landlord/upsert/4-RentDeposit.vue';
import RentIncludes from '@/blueprint/components/landlord/upsert/5-RentIncludes.vue';
import BillEstimates from '@/blueprint/components/landlord/upsert/6-BillEstimates.vue';
import Fees from '@/blueprint/components/landlord/upsert/7-Fees.vue';
import Certificates from '@/blueprint/components/landlord/upsert/8-Certificates.vue';
import Other from '@/blueprint/components/landlord/upsert/9-Other.vue';
import Images from '@/blueprint/components/landlord/upsert/10-Images.vue';

import { API } from '@lordly/models2/interfaces/gql';
import { LandlordState } from '@/store/landlord/state';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { NotifyAPIOptions } from '@/interfaces/quasar';
import { ActionPayload } from '@/interfaces';
import { debounce } from 'quasar';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { MutatePortfolioPayload } from '@/store/landlord/mutations';

export default Vue.extend({
  name: 'Portfolio-Upsert-Page',
  components: {
    Address,
    LatLng,
    DetailsDates,
    RentDeposit,
    RentIncludes,
    BillEstimates,
    Fees,
    Certificates,
    Other,
    Images,
  },
  data () {
    return {
      formSections: [
        {
          label: 'Address',
          component: 'Address',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Details & Dates',
          component: 'DetailsDates',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Rent & Deposit',
          component: 'RentDeposit',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Rent Includes',
          component: 'RentIncludes',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Bills Estimates',
          component: 'BillEstimates',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Fees',
          component: 'Fees',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Certificates',
          component: 'Certificates',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Additional Info',
          component: 'Other',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
        {
          label: 'Upload Images',
          component: 'Images',
          icon: 'mdi-alert-circle',
          colour: 'red',
          valid: false,
          warning: false,
          error: false,
        },
      ],
      currentSection: 0,
      overrideSection: '',
      overrideSectionData: null,
      animate: false,
      animateClearInterval: -1,
      addingProperty: false,
      update: false,
      updateDebounce: () => {
        return;
      },
    };
  },
  computed: {
    currentDynamicComponent (): string {
      const override: string | undefined = this.overrideSection;
      const current: string = this.formSections[this.currentSection].component;
      return override ? override : current;
    },
    allValid (): boolean {
      let count: number = 0;
      for (const idx in this.formSections) {
        if (this.formSections[idx]) {
          const section: Section = this.formSections[idx];
          if (!section.error && !section.warning && section.valid) {
            count++;
          }
        }
      }
      if (this.formSections.length === count) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    allValid () {
      if (this.allValid) {
        this.animateClearInterval = window.setInterval(() => {
          this.animate = !this.animate;
        }, 1000);
      }
    },
  },
  created () {
    // Determine if in update mode
    const inUpdateMode: boolean = this.$route.path.indexOf('update') > -1;
    // If in update mode
    if (inUpdateMode) {
      // Check if form id is set in update-mode, if not then reroute to portfolio
      if (!(this.$store.state.landlord.form as LandlordState['form']).extras.id) {
        this.$router.push('/landlord/portfolio');
      }
      // Make all sections valid
      for (const idx in this.formSections) {
        if (this.formSections[idx]) {
          this.currentSection = parseInt(idx);
          this.validSection();
        }
      }
      // Reset to first
      this.currentSection = 0;
      // Mark component in update mode
      // DevNote: Done after all sections as valid, because debounce update is triggered in valid sections
      this.update = inUpdateMode;
      // Setup debounce update
      this.updateDebounce = debounce(() => {
        this.updateProperty();
      }, 1000) as () => void;
     }
    // Ping Blob Endpoints so that they can warm up
    this.$blob.Warm();
  },
  beforeDestroy () {
    if (this.animateClearInterval !== -1) {
      window.clearInterval(this.animateClearInterval);
    }
  },
  methods: {
    setOverrideComponent (payload: Record<string, any>) {
      this.overrideSection = payload.component;
      this.overrideSectionData = payload.data;
    },
    validSection () {
      // Get current segment & mark as valid
      const currentSection: Section = this.formSections[this.currentSection];
      // If in update mode, invoke debounce update
      if (this.update && (currentSection.warning || currentSection.error)) {
        this.updateDebounce();
      }
      currentSection.error = false;
      currentSection.warning = false;
      currentSection.valid = true;
      this.updateSectionIcon();
      // Determine next view
      this.move(true);
    },
    move (forward: boolean) {
      this.overrideSection = '';
      this.overrideSectionData = null;
      const direction: number = forward ? (this.currentSection + 1) : (this.currentSection - 1);
      if (this.formSections[direction]) {
        this.currentSection = direction;
      }
    },
    scrollToBottom () {
       // Component
      const container: Element = this.$refs['container'] as Element;
      const domElement: Vue = this.$refs['component'] as Vue;
      container.scrollTop = container.scrollHeight;
    },
    touchedSection () {
      if (!this.formSections[this.currentSection].warning) {
        this.formSections[this.currentSection].warning = true;
        this.formSections[this.currentSection].valid = false;
      }
      this.updateSectionIcon();
    },
    updateSectionIcon () {
      const section: Section = this.formSections[this.currentSection];
      if (section.error) {
        section.icon = 'mdi-alert-circle';
        section.colour = 'red';
      } else if (section.warning) {
        section.icon = 'mdi-alert';
        section.colour = 'orange';
      } else {
        section.icon = 'mdi-check-circle';
        section.colour = 'green';
      }
      this.formSections[this.currentSection] = section;
    },
    async addProperty () {
      // Set loading to true
      this.addingProperty = true;
      // Create Query
      const query: GQLTagRequestObject = gql`
        mutation ($subscriptionId: String!, $property: IPropertyInfo!) {
          CreateProperty(
            input: {
              subscriptionId: $subscriptionId,
              property: $property
            }
          ) {
            id
          }
        }
      `;
      // Create payload
      const landlordState: LandlordState = this.$store.state.landlord as LandlordState;
      // Create a copy of form
      const payloadForm: LandlordState['form'] = Object.assign({}, landlordState.form);
      delete payloadForm.extras;
      const payload: API.CreatePropertyInput = {
        subscriptionId: landlordState.user.subscription,
        property: payloadForm as unknown as Partial<Property>, // Converted to Unknown as subtypes within partial property don't quite match up
      };
      // Send Request
      try {
        const response: Partial<Property> = await this.$gql.Mutation<Partial<Property>>('CreateProperty', query, payload);
        // Create notification payload
        let notifyPayload: NotifyAPIOptions = {
          position: 'bottom-right',
          message: 'Property Created',
          timeout: 2500,
          color: 'green',
          textColor: 'white',
          icon: 'mdi-thumb-up',
        };
        // Display payoad
        if (response && response.id) {
          this.$q.notify(notifyPayload);
          // Redirect to portfolio page
          this.$router.push('/landlord/portfolio');
          // Invoke another get
          await this.$store.dispatch('landlord/actionGetPortfolio', { component: this } as ActionPayload);
        } else {
          // Alter message
          notifyPayload.message = 'Something went wrong...';
          notifyPayload.icon = 'mdi-exclamation';
          notifyPayload.color = 'red';
          this.$q.notify(notifyPayload);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.addingProperty = false;
      }
    },
    async updateProperty () {
      // Create Query
      const query: GQLTagRequestObject = gql`
        mutation ($id: String!, $partition: String!, $property: IPropertyInfo!) {
          UpdateProperty(
            input: {
              id: $id,
              partition: $partition,
              property: $property
            }
          ) {
            id
            images {
              id
              url
            }
            address {
              line1
              city
              partition
            }
            available
            meta {
              createdOn
              lastUpdatedOn
            }
          }
        }
      `;
      // Create payload
      const landlordState: LandlordState = this.$store.state.landlord as LandlordState;
      // Create a copy of form
      const payloadForm: LandlordState['form'] = Object.assign({}, landlordState.form);
      const payload: API.UpdatePropertyInput = {
        id: payloadForm.extras.id,
        partition: payloadForm.extras.partition,
        property: {} as unknown as Partial<Property>, // Converted to unknown as subtypes within partial property don't quite match up
      };
      // Delete excess variables from form
      const portfolioIdx: number = payloadForm.extras.idx;
      delete payloadForm.extras;
      // Set payload after cleaning...
      payload.property = payloadForm as unknown as Partial<Property>;
      // Send Request
      try {
        const response: Partial<Property> = await this.$gql.Mutation('UpdateProperty', query, payload);
        // Update portfolio property info
        const mutationPayload: MutatePortfolioPayload = {
          index: String(portfolioIdx),
          body: response,
        };
        this.$store.commit('landlord/MutatePortfolioProperty', mutationPayload);
      } catch (e) {
        console.error(e);
      }
    },
    goBack () {
      this.$router.go(-1);
    },
  },
});

interface Section {
  label: string;
  component: string;
  icon: string;
  colour: string;
  valid: boolean;
  warning: boolean;
  error: boolean;
}
