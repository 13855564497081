









































import Vue from 'vue';
import Form from '@/blueprint/components/landlord/upsert/Form.vue';
import { LandlordState } from '@/store/landlord/state';
import { MutationPayload, KeyValuePair } from '@/interfaces';
import { ValidateFields } from '@/assets/mixins';
import { GenerateCypressName } from '@/assets/mixins';

export default Vue.extend({
  name: 'Form-BillEstimates-Component',
  components: { 'input-form': Form },
  props: {
    update: {
      type: Boolean,
      default: false,
      required: true,
    },
    section: {
      type: Object,
      default: () => {
        return {
          label: '',
          component: '',
          icon: '',
          colour: '',
          valid: false,
          warning: false,
          error: false,
        };
      },
      required: true,
    },
  },
  data () {
    return {
      form: {
        billEstimates: [] as KeyValuePair[],
      },
      refs: [] as string[],
    };
  },
  created () {
    this.syncForm();
  },
  methods: {
    syncForm () {
      const form: LandlordState['form'] = this.$store.state.landlord.form;
      // Loop through array to remove Vuex observers
      for (const idx in form.billEstimates) {
        if (form.billEstimates[idx]) {
          // Populate form
          const keyValuePair: KeyValuePair = {
            label: form.billEstimates[idx].label,
            value: form.billEstimates[idx].value,
          };
          this.form.billEstimates.push(keyValuePair);
          // Store refs
          const newRef: string = this.generateRefValue(form.billEstimates[idx].label);
          this.refs.push(newRef);
        }
      }
    },
    validate () {
      let hasError: boolean = !this.validateFields(this.refs);
      if (!hasError) {
        this.updateVuexStore('form.billEstimates', this.form.billEstimates);
        this.$emit('valid');
      } else {
        this.$emit('scrollBottom');
      }
    },
    touched () {
      this.$emit('touched');
    },
    generateRefValue (name: string) {
      return name.replace(/([\/])|([ ])/gm, '').toLowerCase();
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    updateVuexStore (key: string, value: KeyValuePair[]) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
    generateCypressName (input: string) {
      return GenerateCypressName(input);
    },
  },
});
